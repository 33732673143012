import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { formatter, MappedElement } from "../../utils/methods";
import { CImage } from "../../uiComponents";
import CIcon from "../../assets/cIcon/CIcon";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/Auth.action";
import { BsWallet2 } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import { CompanyIcon } from "../../assets/svg";
import { Tag } from "antd";
import packageJson from '../../../package.json';

const SideMenuItem = ({ title, path, activeClass, close, icon, pathName, showTag, activeStatus }) => {

  return (
    <li className="side-menu-list-item">
      <NavLink
        to={path}
        id={path}
        className={({ isActive }) => (path !== '/' ? isActive ? "active" : activeClass : pathName === '/' ? 'active' : '')}
        onClick={close}
      >
        {icon ? <CImage url={icon} className="icon-class" /> : null}
        {title}
        {/* {showTag && activeStatus ? (
          <Tag className="activeTag" color="success">
            Pending
          </Tag>
        ) : null} */}
      </NavLink>
    </li>
  );
};

function SideMenu({ close, routes, user, activeStatus }) {

  const dispatch = useDispatch();
  const location = useLocation();

  const logoutFunc = () => {
    dispatch(logout());
  };

  const renderMenu = (menus) => {
    if (menus) {
      return (
        <ul>
          <MappedElement
            data={menus}
            renderElement={(menu, menuIndex) => {
              const { hideInMenu = false } = menu;
              return !hideInMenu ? (
                <SideMenuItem
                  key={menuIndex}
                  close={close}
                  title={menu.title}
                  icon={menu.icon}
                  path={menu.path}
                  pathName={location?.pathname}
                  activeStatus={activeStatus}
                  showTag={menu?.showTag}
                />
              ) : null;
            }}
          />
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="side-menu">
      <button className="side-menu-header-button" onClick={close}>
        <FiX />
      </button>
      <div className="side-menu-header">
        {/* <div className="logo" >
                <CImage url={require('../../assets/images/logo-white.png')} />
            </div>
            <CImage url={require('../../assets/images/logo-white.png')} /> */}
        <div className="company-name">
          <CImage
            url={require("../../assets/images/logo-white.png")}
            className="logo-white"
          />
          {/* <CImage url={companyIcon} /> */}
        </div>
      </div>
      <div className="side-menu-body">
        <div className="user-detail">
          <CompanyIcon />
          {user?.name ? <h3>{user?.name || ""}</h3> : null}
          {!user?.name?.length ? <h3>{user?.companyName || ""}</h3> : <h5>Client: {user?.companyName || ""}</h5>}
          {user?.clientId ? <h5>Client ID: {user?.clientId}</h5> : null}
          {/* <CImage
                        src={companyIcon}
                         /> */}
          {/* <div className="user-wallet" >
                        <CImage url={require('../../assets/images/wallet.png')} />
                        <p>{formatter(2543)}</p>
                    </div> */}
        </div>
        {renderMenu(routes)}
      </div>
      <div className="side-menu-footer">
        <a
          href="#"
          className="side-menu-footer-text side-menu-footer-link"
          onClick={() => logoutFunc()}
        >
          <CImage
            url={require("../../assets/images/arrowleftfooter.png")}
            className="sidemenuarrow"
          />{" "}
          &nbsp; Logout from Dashboard
        </a>
        <p className="side-menu-footer-text">Version: {packageJson?.version}</p>
        <p className="side-menu-footer-text">© 2022 All Rights Reserved</p>
      </div>
      <CImage
        className="side-menu-background-image"
        url={require("../../assets/images/side-menu-background.png")}
      />
    </div>
  );
}
export default React.memo(SideMenu);
