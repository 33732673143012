import { post, get } from "../../utils/apiMethods";
import CommonConstant from "../constants/Common.constant";
import { handleError, handleSuccess } from "../../utils/methods";
import { setValueIntoHeaders } from "../../utils/asyncStorage/Functions";

export const toggleLeftDrawer = (value) => (dispatch) => {
  dispatch({ type: CommonConstant.TOGGLE_LEFT_DRAWER, value: value });
};

export const selectCountry = () => (dispatch) => {
  let selectedCountry = {
    "name": "United Arab Emirates",
    "code": "+971",
    "iso": "AE",
    "flag": "https://www.countryflags.io/AE/flat/24.png",
    "mask": ["99 999 9999", "9 999 9999"],
    pattern: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
  }
  dispatch({ type: CommonConstant.CURRENT_COUNTRY, country: selectedCountry })
};

export const getCountries = (values, CB) => (dispatch) => {
  dispatch({ type: CommonConstant.GET_COUNTRIES, loading: true });
  post(`masters/getAllCountries`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: CommonConstant.GET_COUNTRIES,
          loading: false,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
      }
    })
    .catch((error) => {
      handleError(
        error?.data?.message ||
        error?.response?.data?.message ||
        "Something went wrong!"
      );
      dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
    });
};

export const getStates = (values, CB) => (dispatch) => {
  dispatch({ type: CommonConstant.GET_STATES, loading: true });
  post(`masters/getAllStates`, values)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: CommonConstant.GET_STATES,
          loading: false,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: CommonConstant.GET_STATES, loading: false });
      }
    })
    .catch((error) => {
      handleError(
        error?.data?.message ||
        error?.response?.data?.message ||
        "Something went wrong!"
      );
      dispatch({ type: CommonConstant.GET_STATES, loading: false });
    });
};

export const getAllClients = (payload, CB) => (dispatch) => {
  const dispatchType = CommonConstant.GET_ALL_CLIENTS;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/getClientChildren`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.entries });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const changeMainClient = (_id, CB) => (dispatch) => {
  setValueIntoHeaders(_id);
  dispatch({ type: CommonConstant.SELECTED_CLIENT, data: _id });
  localStorage.setItem('CLIENT', _id);
  CB && CB();
};

export const downloadCSV = (payload, uri, fileName) => (dispatch) => {
  let dispatchType = CommonConstant.DOWNLOAD_CSV;
  dispatch({ type: dispatchType, loading: true });
  post(uri, payload)
    .then(async ({ data }) => {
      try {
        let link = document.createElement("a");
        let d = new Date();
        link.download = `${fileName}-${d.toISOString()}.csv`;
        let blob = new Blob([data], { type: "text/csv" });
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
        dispatch({ type: dispatchType, loading: false });
      } catch (err) {
        console.log('error', err);
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const getAllTopics = () => (dispatch) => {
  const dispatchType = CommonConstant.GET_ALL_TOPICS;
  dispatch({ type: dispatchType, loading: true, data: [] });
  get(`clients/getClientApprovalTopics`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.topics });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
      dispatch({ type: dispatchType, loading: false });
    });
};

export const refreshTokenTimer = (isTimer) => (dispatch) => {
  let dispatchType = CommonConstant.REFRESH_TOKEN_TIMER;
  dispatch({ type: dispatchType, data: isTimer });
};

export const getConstants = (CB) => (dispatch) => {
  const dispatchType = CommonConstant.GET_CONSTANTS;
  dispatch({ type: dispatchType, loading: true });
  get(`generic/getConstants`)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        CB && CB();
      } else {
        handleError(data?.data?.message || 'Something went wrong!');
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
      dispatch({ type: dispatchType, loading: false });
    });
};