import SALARY_STATUS from './../constants/SalaryStatus.constant';

const initialState = {
    getLoading: false,
    salaryStatus: [],
    salaryStatusMetaData: {},

    getSalaryStatusDetailLoading: false,
    downloadSalaryStatusDetailLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case SALARY_STATUS.GET_SALARY_STATUS:
            return {
                ...state,
                getLoading: action.loading,
                salaryStatus: action.data || state.salaryStatus,
                salaryStatusMetaData: action.metaData || state.salaryStatusMetaData
            };

        case SALARY_STATUS.GET_SALARY_STATUS_DETAIL:
            return {
                ...state,
                getSalaryStatusDetailLoading: action.loading,
            };

        case SALARY_STATUS.DOWNLOAD_SALARY_STATUS_DETAIL:
            return {
                ...state,
                downloadSalaryStatusDetailLoading: action.loading,
            };

        default:
            return state;
    }
};
